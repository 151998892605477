<script setup lang="ts">
import type {
  IManagerFieldEmits,
  IManagerFieldProps,
} from '@manager/components/Field/types'
import {
  useField,
  useFieldCache,
  useFieldCalculation,
  useFieldCast,
} from '@manager'

const props = withDefaults(defineProps<IManagerFieldProps>(), {
  parentValue: () => ({}),
})
const emit = defineEmits<IManagerFieldEmits>()

const { modelValue, parentValue, handleChange, handleBlur } = useField(
  props,
  emit,
)

const { castPlugin } = useFieldCast(props.node)

const { registerCalculated } = useFieldCalculation(props.node, parentValue)
registerCalculated()

useFieldCache(props.node, parentValue)
</script>

<template>
  <ManagerField v-bind="props">
    <template #default="{ id, name, label }">
      <Tooltip
        class="bg-gray-950 text-xs"
        outer-class="grow flex"
        :content="node.tooltip ? node.tooltip : undefined"
        placement="top"
      >
        <FormKit
          :id="id"
          v-model="modelValue"
          type="number-increment"
          :name="name"
          :label="label"
          label-class="overflow-ellipsis whitespace-nowrap overflow-hidden"
          :plugins="[castPlugin]"
          :min="node.min ?? undefined"
          :max="node.max ?? undefined"
          @input="handleChange"
          @blur="handleBlur"
        />
      </Tooltip>
    </template>
  </ManagerField>
</template>
